import { en } from 'vuetify/locale'

export default {
  ...en,
  language: {
    tooltip: 'Change language',
    en: 'English',
    cs: 'Czech'
  },
  logout: {
    tooltip: 'Logout'
  },
  appTitle: {
    title: 'OAF',
  },
  noDepartments: 'No departments defined.',
  noService: 'No service defined.',
  noForm: 'No form defined.',
  noUser: 'No user signed in.',
  dashboard: 'Dashboard',
  pageNotFound: '404: Page not found',
  formSendSuccess: 'Form has been submitted successfully.',
  formLoadSuccess: 'Form data has been loaded successfully.',
  formValid: 'Pretty nice, your form IS VALID.',
  formNotValid: 'Form IS NOT VALID. Please check all form fields again.',
  formLoad: 'Load form',
  formSend: 'Submit',
  formUpdate: 'Update form',
  formValidate: 'Validate',
  formClearValidations: 'Clear validations',
  formClearData: 'Clear data',
  formValidationsCleared: 'All form validations was cleared.',
  formDataCleared: 'All form data was cleared.',
  printForm: 'Print form',
  collapseAll: 'Collapse all',
  collapseAllGroups: 'Collapse all groups',
  expandAll: 'Expand all',
  expandAllGroups: 'Expand all groups',
  groupRemove: 'Remove set',
  groupAdd: 'Add next set',
  formEditable: 'Editable form',
  validationMessages: {
    required: 'This field is required.',
    email: 'Please use correct email address (e.g.: name.surname@company.com).',
    numeric: 'Please use numbers only.',
    phone: 'Please use correct phone number (e.g.: +420 456 785 236).',
    filesExtensions: 'Please, use files with these extensions: ',
    maxFileSize: 'File must have less than ',
    maxFilesLength: 'Too much files! Maximum is ',
    exactFilesLength: 'Please, select exactly '
  },
  groups: 'groups',
  sections: 'sections',
  calendarActions: {
    ok: 'Ok',
    cancel: 'Cancel',
  }

  // datePicker: {
  //   ok: 'EN Ok',
  //   cancel: 'EN Cancel',
  //   range: {
  //     title: 'EN Range title',
  //     header: 'EN Range header',
  //   },
  //   title: 'EN Title',
  //   header: 'EN Header',
  //   input: {
  //     placeholder: 'EN Input placehodler'
  //   }
  // }
}
